import React from 'react';

import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { 
  faGithub
} from '@fortawesome/free-brands-svg-icons';
import {
  faWindowMaximize
} from '@fortawesome/free-regular-svg-icons';

const ProjectList = ({ updatePage }) => (
  <div className="project-list display-body">
    <div className="project-list-item">
      <img 
        src={process.env.PUBLIC_URL + '/clamor_splash.png'} 
        alt="Clamor Image"
        className="unshifted-image" />
      <div className="project-list-item-overlay">
        <div className="project-list-item-title">
          <h1 onClick={updatePage(2)}>Clamor</h1>
        </div>
        <div className="project-list-item-links">
          <div className="project-list-item-link">
            <a 
              href="https://github.com/vincentahn/clamor"
              target="_blank" 
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="project-list-item">
      <img 
        src={process.env.PUBLIC_URL + '/project_hawkeye.png'} 
        alt="Project Hawkeye Image" 
        className="shifted-image" />
      <div className="project-list-item-overlay">
        <div className="project-list-item-title">
          <h1 onClick={updatePage(3)}>Project Hawkeye</h1>
        </div>
        <div className="project-list-item-links">
          <div className="project-list-item-link">
            <a 
              href="https://github.com/aahmed019/project-hawkeye"
              target="_blank" 
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
          <div className="project-list-item-link">
            <a 
              href="https://project-hawkeye.herokuapp.com/"
              target="_blank" 
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWindowMaximize} />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="project-list-item">
      <img 
        src={process.env.PUBLIC_URL + '/sermo.png'} 
        alt="Project Hawkeye Image" 
        className="shifted-image" />
      <div className="project-list-item-overlay">
        <div className="project-list-item-title">
          <h1 onClick={updatePage(4)}>Sermo</h1>
        </div>
        <div className="project-list-item-links">
          <div className="project-list-item-link">
            <a 
              href="https://github.com/vincentahn/sermo"
              target="_blank" 
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
          <div className="project-list-item-link">
            <a 
              href="https://vincentahn.github.io/sermo/"
              target="_blank" 
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWindowMaximize} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProjectList;