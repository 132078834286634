import React from 'react';

class NameContainer extends React.Component{
  render(){
    return(
      <div className="name-container">
        <h1>VINCENT AHN</h1>
      </div>
    );
  }
};

export default NameContainer;