import React from 'react';
import { Link } from 'react-router-dom';

class RightNavBar extends React.Component{
  render(){
    return(
      <div className="right-nav-bar">
        <div className="nav-link-icon">
          <Link to="/about">
            <h1>About</h1>
          </Link>
        </div>
        <div className="nav-link-icon">
          <Link to="/projects">
            <h1>Projects</h1>
          </Link>
        </div>
        {/* <div className="nav-link-icon">
          <Link to="/credits">
            <h1>Credits</h1>
          </Link>
        </div> */}
      </div>
    );
  }
};

export default RightNavBar;