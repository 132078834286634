import React from 'react';

import CloseButton from '../close-button';
import ProjectList from './project-list';
import Clamor from './clamor';
import ProjectHawkeye from './project-hawkeye';
import Sermo from './sermo';

import HomeButton from './home-button';

class Projects extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      page: 1
    };

    this.updatePage = this.updatePage.bind(this);
  }

  updatePage(num){
    return e => {
      this.setState({ page: num });
    };
  }

  render(){
    const title = {
      1: <h1>Projects</h1>,
      2: <h1>Clamor</h1>,
      3: <h1>Project Hawkeye</h1>,
      4: <h1>Sermo</h1>
    };

    const displayPage = {
      1: <ProjectList updatePage={this.updatePage} />,
      2: <Clamor updatePage={this.updatePage} />,
      3: <ProjectHawkeye updatePage={this.updatePage} />,
      4: <Sermo updatePage={this.updatePage} />
    };

    const footerContent = page => {
      switch(page){
        case 1:
          return null;

        default:
          return <HomeButton updatePage={this.updatePage} />;
      }
    }

    return(
      <div className="projects-modal display">
        <CloseButton />
        <div className="title-heading">
          {title[this.state.page]}
        </div>
        {displayPage[this.state.page]}
        <div className="project-footer">
          {footerContent(this.state.page)}
        </div>
      </div>
    );
  }
};

export default Projects;