import React from 'react';

import CloseButton from './close-button';

class About extends React.Component{
  render(){
    let birthday = new Date("05/04/1997");
    let today = new Date();

    let yearDiff = Math.floor((today.getTime() - birthday.getTime()) / (1000 * 3600 * 24 * 365.25));

    return(
      <div className="about-page display">
        <CloseButton />
        <div className="title-heading">
          <h1>About Me</h1>
        </div>
        <div className="display-body about-info-container">
          <div className="row row-1">
            <div className="column profile-container">
              <img src={process.env.PUBLIC_URL + '/profile.png'} alt="" />
            </div>
            <div className="column description-container">
              <div>
                <h2>Description</h2>
              </div>

              <br />

              <div>
                <p>
                  My name is Vincent Ahn and I am a software developer with experience building complex web applications.
                </p>

                <br />

                <p>
                  Currently I am working at AbleTo's Care Delivery team where we are developing an interactive template for therapists and behavioral coaches to use during sessions with their patients.
                </p>

                <br />

                <p>
                  When I'm not coding, I usually spend my time playing FPS/MOBA games, binging Netflix and anime, or taking walks outside.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-2">
            <div className="attribute-table-container column">
              <table>
                <tr>
                  <th>
                    Age
                  </th>
                  <th>
                    Gender
                  </th>
                </tr>
                <tr>
                  <td>
                    {yearDiff}
                  </td>
                  <td>
                    Male
                  </td>
                </tr>
                <tr>
                  <th>
                    Location
                  </th>
                  <th>
                    Hobbies
                  </th>
                </tr>
                <tr>
                  <td>
                    Watervliet, NY
                  </td>
                  <td>
                    Games, Anime, Podcasts
                  </td>
                </tr>
              </table>
            </div>
            <div className="skills-section column">
              <div>
                Skills
              </div>
              <div>
                <ul>
                  <li>
                    <i className="devicon-javascript-plain"></i>
                    <h3>Javascript</h3>
                  </li>
                  <li>
                    <i class="devicon-typescript-plain"></i>
                    <h3>Typescript</h3>
                  </li>
                  <li>
                    <i className="devicon-python-plain"></i>
                    <h3>Python</h3>
                  </li>
                  <li>
                    <i className="devicon-ruby-plain"></i>
                    <h3>Ruby</h3>
                  </li>
                  <li>
                    <i className="devicon-react-original"></i>
                    <h3>React</h3>
                  </li>
                  <li>
                    <i className="devicon-redux-original"></i>
                    <h3>Redux</h3>
                  </li>
                  <li>
                    <i className="devicon-nodejs-plain"></i>
                    <h3>NodeJS</h3>
                  </li>
                  <li>
                    <i className="devicon-express-original"></i>
                    <h3>ExpressJS</h3>
                  </li>
                  <li>
                    <i className="devicon-rails-plain"></i>
                    <h3>Ruby on Rails</h3>
                  </li>
                  <li>
                    <i className="devicon-html5-plain"></i>
                    <h3>HTML</h3>
                  </li>
                  <li>
                    <i className="devicon-css3-plain"></i>
                    <h3>CSS</h3>
                  </li>
                  <li>
                    <i class="devicon-sass-original"></i>
                    <h3>Sass</h3>
                  </li>
                  <li>
                    <i className="devicon-postgresql-plain"></i>
                    <h3>PostgreSQL</h3>
                  </li>
                  <li>                  
                    <i className="devicon-mongodb-plain"></i>
                    <h3>MongoDB</h3>
                  </li>
                  <li>
                    <i className="devicon-webpack-plain"></i>
                    <h3>Webpack</h3>
                  </li>
                  <li>
                    <i className="devicon-jquery-plain"></i>
                    <h3>jQuery</h3>
                  </li>
                  <li>
                    <i className="devicon-git-plain"></i>
                    <h3>Git</h3>
                  </li>
                  <li>
                    <i className="devicon-heroku-original"></i>
                    <h3>Heroku</h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default About;