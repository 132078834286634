import React from 'react';

import CloseButton from './close-button';

class Credits extends React.Component{
  render(){
    return(
      <div className="credits-page display">
        <CloseButton />
        <div className="title-heading">
          <h1>Credits</h1>
        </div>
        <div className="credits-body display-body">
          <div>
            <h2>This website was able to be made thanks to:</h2>
          </div>
          <div className="table-container">
            <div className="accreditation-row">
              <div className="accreditation-col">
                <a 
                  href="https://unsplash.com/@jeremyperkins?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" 
                  target="_blank" 
                  rel="noopener noreferrer">
                  Jeremy Perkins
                </a> from <a 
                  href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" 
                  target="_blank" 
                  rel="noopener noreferrer">
                  Unsplash
                </a>
              </div>
              <div className="accreditation-col">
                for the Space Background Picture
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Credits;