import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { 
  faTimes 
} from '@fortawesome/free-solid-svg-icons';

const CloseButton = () => (
  <div className="close-button-container">
    <Link to="">
      <FontAwesomeIcon icon={faTimes} />
    </Link>
  </div>
);

export default CloseButton;