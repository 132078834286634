import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const HomeButton = ({ updatePage }) => (
  <div className="home-button-container">
    <FontAwesomeIcon icon={faHome} onClick={updatePage(1)} />
  </div>
);

export default HomeButton;