export const SET_WELCOME = 'SET_WELCOME';
export const SET_LOADED = 'SET_LOADED';

const setWelcome = () => ({
  type: SET_WELCOME
})

const setLoaded = () => ({
  type: SET_LOADED
})

export const receiveNewUser = () => dispatch => {
  dispatch(setWelcome());
}

export const finishLoading = () => dispatch => {
  dispatch(setLoaded());
}