import { SET_LOADED, SET_WELCOME } from "../../actions/ui/welcome/welcome_actions";

const welcomeReducer = (oldState = '', action) => {
  switch(action.type){
    case SET_WELCOME:
      return 'welcome';

    case SET_LOADED:
      return 'loaded';

    default:
      return oldState;
  }
}

export default welcomeReducer;