class Star{
  constructor(cvs, ctx){
    this.cvs = cvs;
    this.ctx = ctx;

    this.color = 'white';
    this.posX = Math.random();
    this.posY = Math.random();

    this.size = Math.floor(Math.random() * 5);

    this.velocity = Math.random() * 0.001;
  }

  confirmMax(){
    this.maxWidth = this.cvs.width;
    this.maxHeight = this.cvs.height;
  }

  render(){
    this.confirmMax();

    this.ctx.beginPath();
    this.ctx.fillStyle = this.color;
    this.ctx.arc(
      Math.floor(this.posX * this.maxWidth), 
      Math.floor(this.posY * this.maxHeight), 
      this.size / 2, 
      0, 
      2 * Math.PI
    );
    this.ctx.fill();
  }

  animate(){
    this.posY -= this.velocity;

    if(this.posY < 0){
      this.posY = 1;
    }
  }
}

export default Star;