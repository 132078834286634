import React from 'react';

import Battery from './battery';

import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { 
  faGithub
} from '@fortawesome/free-brands-svg-icons';

// HTML, SASS, eRuby, NodeJS, ReactJS, Redux, Ruby on Rails, PostgreSQL

const Clamor = ({ updatePage }) => (
  <div className="project-page display-body">
    <div className="row row-1">
      <div className="column project-image-container">
        <img 
          className="project-image"
          src={process.env.PUBLIC_URL + '/clamor_splash.png'} 
          alt="Clamor Image" />
        
        <div className="project-page-image-overlay">
          <div className="project-page-image-links">
            <div className="project-page-image-link">
              <a 
                href="https://github.com/vincentahn/clamor"
                target="_blank" 
                rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="column project-description">
        <p>Full-stack clone of Discord; allows users to communicate with each other via servers and channels</p>

        <br />

        <p>Implemented Rails Active Record Validation with a customized frontend component to generate field-specific, responsive error messages for the user during signup authentication</p>

        <br />

        <p>Incorporated Rails Action Cable to develop a messaging system that allows users to transmit and receive both text channel messages and private channel messages with notifications in real-time simultaneously</p>

        <br />
        
        <p>Designed SQL queries in conjunction with Rails Active Record Associations to pre-fetch data from multiple tables in a single query, allowing for large amounts of data to be sent to the frontend with minimized server load</p>
      </div>
    </div>
    <div className="row row-2">
      <div className="display-technologies-container">
        <div className="title">
          <h1>Powered By:</h1>
        </div>
        <Battery technologies={[
          'HTML',
          'SASS',
          'NodeJS',
          'ReactJS',
          'Redux',
          'Ruby on Rails',
          'PostgreSQL'
        ]} />
      </div>
    </div>
  </div>
);

export default Clamor;