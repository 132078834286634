import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faGithub, 
  faLinkedin, 
  faAngellist 
} from '@fortawesome/free-brands-svg-icons';
import { 
  faFile,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

class LeftNavBar extends React.Component{
  render(){
    return(
      <div className="left-nav-bar">
        <div className="nav-link-icon">
          <a href="https://github.com/vincentahn" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
        <div className="nav-link-icon">
          <a href="https://www.linkedin.com/in/junminvincentahn/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
        <div className="nav-link-icon">
          <a href="https://angel.co/u/jun-min-vincent-ahn" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faAngellist} />
          </a>
        </div>
        <div className="nav-link-icon">
          <a href={process.env.PUBLIC_URL + '/Resume.pdf'} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFile} />
          </a>
        </div>
        <div className="nav-link-icon">
          <a href="mailto:vincentahnbusiness@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    );
  }
};

export default LeftNavBar;