import React from 'react';
import { Route } from 'react-router-dom';

import Credits from './credits';
import Projects from './projects/projects';
import About from './about';

const Display = () => (
  <div className="display-container">
    <Route exact path="/credits" component={Credits} />
    <Route exact path="/projects" component={Projects} />
    <Route exact path="/about" component={About} />
  </div>
);

export default Display;