import React from 'react';

import Canvas from './background/canvas';
import NameContainer from './name/name';
import LeftNavBar from './left-nav-bar/left-nav-bar';
import Display from './display/display';
import RightNavBar from './right-nav-bar/right-nav-bar';

class App extends React.Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.receiveNewUser();

    setTimeout(this.props.finishLoading, 5000);
  }
  
  render(){
    return(
      <div className="app">
        <div className="app-background">
          <Canvas />
        </div>
        <div className="header">
          <NameContainer />
        </div>
        {this.props.welcomeState === 'loaded'
          ?
          <div className="body">
            <LeftNavBar />
            <Display />
            <RightNavBar />
          </div>
          : null}

        <div className="footer">
        </div>
      </div>
    );
  }
}

export default App;