import React, { useRef, useEffect } from 'react';
import Star from '../../util/star';

const Canvas = React.memo(props => {
  const canvasRef = useRef(null);
  const numStars = 600;
  let stars = [];

  const setup = (cvs, ctx) => {
    for(let i = 0; i < numStars; i++){
      stars.push(new Star(cvs, ctx));
    }
  }

  const draw = () => {
    canvasRef.current.width = canvasRef.current.parentElement.clientWidth;
    canvasRef.current.height = canvasRef.current.parentElement.clientHeight;

    stars.forEach(star => {
      star.render();
      star.animate();
    });

    window.requestAnimationFrame(draw);
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    setup(canvas, context);

    draw();
  });

  return <canvas ref={canvasRef} {...props} />;
});

export default Canvas;