import React from 'react';

import Battery from './battery';

import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { 
  faGithub
} from '@fortawesome/free-brands-svg-icons';
import {
  faWindowMaximize
} from '@fortawesome/free-regular-svg-icons';

const ProjectHawkeye = ({ updatePage }) => (
  <div className="project-page display-body">
    <div className="row row-1">
      <div className="column project-image-container">
        <img 
          className="project-image"
          src={process.env.PUBLIC_URL + '/project_hawkeye.png'} 
          alt="Project Hawkeye Image" />

        <div className="project-page-image-overlay">
          <div className="project-page-image-links">
            <div className="project-page-image-link">
              <a 
                href="https://github.com/aahmed019/project-hawkeye"
                target="_blank" 
                rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </div>
            <div className="project-page-image-link">
              <a 
                href="https://project-hawkeye.herokuapp.com/"
                target="_blank" 
                rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWindowMaximize} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="column project-description">
        <p>MERN stack team project allowing users to filter through tweets of twitter users</p>

        <br />

        <p>Developed folders with the utilization of Redux’s global store to dispatch actions when adding tweets to or removing tweets from folders and lead to a seamless, uninterrupted user experience</p>

        <br />

        <p>Capitalized on React’s unidirectional data flow coupled with carefully positioned fail-safe mechanisms to produce a robust, single page application</p>
      </div>
    </div>
    <div className="row row-2">
      <div className="display-technologies-container">
        <div className="title">
          <h1>Powered By:</h1>
        </div>
        <Battery technologies={[
          'HTML',
          'SASS',
          'NodeJS',
          'ReactJS',
          'Redux',
          'ExpressJS',
          'MongoDB'
        ]} />
      </div>
    </div>
  </div>
);

export default ProjectHawkeye;