import { connect } from "react-redux";
import { finishLoading, receiveNewUser } from "../actions/ui/welcome/welcome_actions";
import App from "./app";

const mSTP = store => ({
  welcomeState: store.ui.welcome
});

const mDTP = dispatch => ({
  receiveNewUser: () => dispatch(receiveNewUser()),
  finishLoading: () => dispatch(finishLoading())
});

export default connect(mSTP, mDTP)(App);