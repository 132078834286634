import React from 'react';

const getIcon = technology => {
  switch(technology){
    case 'CSS':
      return <i className="devicon-css3-plain"></i>;

    case 'ExpressJS':
      return <i className="devicon-express-original"></i>;

    case 'HTML':
      return <i className="devicon-html5-plain"></i>;

    case 'Javascript':
      return <i className="devicon-javascript-plain"></i>;

    case 'MongoDB':
      return <i className="devicon-mongodb-plain"></i>;

    case 'NodeJS':
      return <i className="devicon-nodejs-plain"></i>;
        
    case 'PostgreSQL':
      return <i className="devicon-postgresql-plain"></i>;

    case 'ReactJS':
      return <i className="devicon-react-original"></i>;

    case 'Redux':
      return <i className="devicon-redux-original"></i>;

    case 'Ruby on Rails':
      return <i className="devicon-rails-plain"></i>;

    case 'SASS':
      return <i className="devicon-sass-original"></i>;

    default:
      return <div>Could not find icon</div>;
  }
}

const Battery = ({technologies}) => {
  const batteries = technologies.map((technology, idx) => {
    let getIndex;

    switch(idx){
      case 0:
        getIndex = "first-technology-item";
        break;

      case technologies.length - 1:
        getIndex = "last-technology-item";
        break;

      default:
        getIndex = "nth-technology-item";
    };
    
    return(
      <div className="technology-container" key={`technology-${idx}`}>
        <div 
          className={`technology-item ${getIndex}`} 
          title={technology}>

          {getIcon(technology)}
        </div>
      </div>
    );
  });

  return(
    <div className="battery">
      <div className="main-body">
        {batteries}
      </div>
      <div className="cap-container">
        <div className="cap"></div>
      </div>
    </div>
  )
};

export default Battery;