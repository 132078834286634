import React from 'react';

import Battery from './battery';

import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { 
  faGithub
} from '@fortawesome/free-brands-svg-icons';
import {
  faWindowMaximize
} from '@fortawesome/free-regular-svg-icons';

const Sermo = ({ updatePage }) => (
  <div className="project-page display-body">
    <div className="row row-1">
      <div className="column project-image-container">
        <img 
          className="project-image"
          src={process.env.PUBLIC_URL + '/sermo.png'} 
          alt="Sermo Image" />

        <div className="project-page-image-overlay">
          <div className="project-page-image-links">
            <div className="project-page-image-link">
              <a 
                href="https://github.com/vincentahn/sermo"
                target="_blank" 
                rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </div>
            <div className="project-page-image-link">
              <a 
                href="https://vincentahn.github.io/sermo/"
                target="_blank" 
                rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWindowMaximize} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="column project-description">
        <p>An interactive sandbox developed in Vanilla Javascript where users can use the power of words to creatively design an environment of their choosing</p>

        <br />

        <p>Expertly integrated Canvas API to render hundreds of different moving shapes with minimal visible delay to ensure a rich user experience</p>

        <br />

        <p>Constructed class inheritance system to heavily DRY up functional code, resulting in more robust code as well as cleaner instantiation of new shapes and alterations</p>

        <br />

        <p>Produced efficient cursor detection algorithm allowing canvas to quickly determine cursor position relative to the hundreds of different shapes being rendered in canvas environment</p>
      </div>
    </div>
    <div className="row row-2">
      <div className="display-technologies-container">
        <div className="title">
          <h1>Powered By:</h1>
        </div>
        <Battery technologies={[
          'HTML',
          'CSS',
          'Javascript'
        ]} />
      </div>
    </div>
  </div>
);

export default Sermo;